import api from "../../api";
import { Action, Dispatch } from "redux";
import * as uniqId from "uniqid";
import { ExceptionResults } from "../../components/typings";
import { MessageBarType } from "@fluentui/react";
import { AutoValidTicketUpdate, SetAutoValidTicketId } from "../autoValid/action";
import { UpdateCarAction } from "../order/ActionInterfaces";

export interface AddMessageStatusAction extends Action {
    type: "ADD_MESSAGE_STATUS";
    messageType: MessageBarType;
    message: string;
    id: string;
}

export interface AddExceptionAction extends Action {
    type: "ADD_EXCEPTION";
    id: string;
    exception: ExceptionResults;
}

export interface RemoveExceptionAction extends Action {
    type: "REMOVE_EXCEPTION";
    exception: ExceptionResults;
}

export interface RemoveMessageStatusAction extends Action {
    type: "REMOVE_MESSAGE_STATUS";
    id: string;
}

export interface FormValidationErrorAction extends Action {
    type: "FORM_VALIDATION_ERROR";
    key: string;
    value: string;
}

export interface UpdateInsurersListAction extends Action {
    type: "UPDATE_INSURERS_LIST";
    insurers: Insurer[];
}
export interface UpdateReviewInsurersListAction extends Action {
    type: "UPDATE_REVIEW_INSURERS_LIST";
    insurers: Insurer[];
}

export interface UpdateLeasersListAction extends Action {
    type: "UPDATE_LEASERS_LIST";
    leasers: Leaser[];
}

export interface UpdateProductSearchQueryAction extends Action {
    type: "UPDATE_PRODUCT_SEARCH_QUERY";
    searchIn?: string;
    term?: string;
}

export interface LoadDebitorsAction extends Action {
    type: "LOAD_DEBITORS";
    debitors: Debitor[];
}

export interface SetCalendarWorker extends Action {
    type: "SET_CALENDAR_OPERATOR";
    operator: string;
}

export interface LoadSelectListContainerAction extends Action {
    type: "LOAD_SELECT_LIST_CONTAINER";
    selectList: SelectListContainer [];
}


export const updateProductSearchQuery = (
    searchIn?: string,
    term?: string
): UpdateProductSearchQueryAction => ({
    type: "UPDATE_PRODUCT_SEARCH_QUERY",
    searchIn,
    term
});

export const removeMessageStatus = (id: string): RemoveMessageStatusAction => ({
    type: "REMOVE_MESSAGE_STATUS",
    id
});

export const addMessageStatus = (statusMessage: StatusMessage) => (
    dispatch: Dispatch
) => {
    const { dismissTimer } = statusMessage;
    const id = statusMessage.id || uniqId();

    // Create action from statusMessage
    const action: AddMessageStatusAction = {
        ...statusMessage,
        type: "ADD_MESSAGE_STATUS",
        id
    };
    dispatch(action);

    if (dismissTimer) {
        setTimeout(() => {
            dispatch(removeMessageStatus(id));
        }, dismissTimer);
    }
};

export const addException = (exception: ExceptionResults ) => (
    dispatch: Dispatch
) => {
    const id = uniqId();
    const action: AddExceptionAction = {
        type: "ADD_EXCEPTION",
        id,
        exception: { ...exception }
    };
    dispatch(action);
};

export const removeException = (
    exception: ExceptionResults
): RemoveExceptionAction => ({ type: "REMOVE_EXCEPTION", exception });

let fetchingInsurers = false;
export const updateInsurersList = () => async (dispatch: Dispatch) => {
    if (fetchingInsurers) return;
    try {
        fetchingInsurers = true;
        const insurers = await api.CMSApi.getInsurers();
        const action: UpdateInsurersListAction = { type: "UPDATE_INSURERS_LIST", insurers };
        dispatch(action);
        fetchingInsurers = false;
    } catch (ex) {
        fetchingInsurers = false;
        addMessageStatus({ messageType: MessageBarType.error, message: `Api: "getInsurers" failed`, dismissTimer: 5000 })(dispatch);
        throw ex;
    }
};

export const getReviewInsurersList = () => async (dispatch: Dispatch) => {
  
    try {
  
        const insurers = await api.Review.getReviewInsurers();
        const action: UpdateReviewInsurersListAction = { type: "UPDATE_REVIEW_INSURERS_LIST", insurers };
        dispatch(action);
     
    } catch (ex) {
       
        addMessageStatus({ messageType: MessageBarType.error, message: `Api: "getInsurers" failed`, dismissTimer: 5000 })(dispatch);
        throw ex;
    }
};

let fetchingDebitors = false;
let debitorQueue = "";
export const getDebitors = (q: string, apiType: ApiType) => async (
    dispatch: Dispatch
) => {
    debitorQueue = q;
    if (fetchingDebitors) return;
    try {
        fetchingDebitors = true;
        do {
            q = debitorQueue;
            const debitors = await api.DebitorApi.getDebitor(q, apiType);

            const action: LoadDebitorsAction = {
                type: "LOAD_DEBITORS",
                debitors
            };
            dispatch(action);
        } while (q !== debitorQueue);
        debitorQueue = "";
        fetchingDebitors = false;
    } catch (ex) {
        fetchingDebitors = false;
        const statusMessage: StatusMessage = {
            messageType: MessageBarType.error,
            message: `Api: "getCustomer" failed with input ${q}`,
            dismissTimer: 5000
        };

        addMessageStatus(statusMessage)(dispatch);

        throw ex;
    }
};

let fetchingLeasers = false;
export const updateLeasersList = () => async (dispatch: Dispatch) => {
    if (fetchingLeasers) return;
    try {
        fetchingLeasers = true;
        const leasers = await api.CMSApi.getLeasers();
        const action: UpdateLeasersListAction = {
            type: "UPDATE_LEASERS_LIST",
            leasers
        };
        dispatch(action);
        fetchingLeasers = false;
    } catch (ex) {
        fetchingLeasers = false;
        const statusMessage: StatusMessage = {
            messageType: MessageBarType.error,
            message: `Api: "getLeasers" failed`,
            dismissTimer: 5000
        };

        addMessageStatus(statusMessage)(dispatch);

        throw ex;
    }
};

export const setCalendarOperator = (operator: string) => (dispatch: Dispatch) => {
    const action: SetCalendarWorker = {
        type: "SET_CALENDAR_OPERATOR",
        operator: operator
    };
    dispatch(action);
};
export const loadSelectList = () => async (dispatch: Dispatch) => {
    const selectListContainer = await api.SelectListContainerApi.getSelectLists();
    const action: LoadSelectListContainerAction = {
        type: "LOAD_SELECT_LIST_CONTAINER",
        selectList: selectListContainer
    };
    dispatch(action);
};

export const createFeedback = (feedback: Feedback) => async (dispatch: Dispatch) => {
    await api.feedbackApi.addFeedback(feedback);
   
    const statusMessage: StatusMessage = {
        messageType: MessageBarType.success,
        message: `Feedback tilføjet`,
        dismissTimer: 5000
    };

    addMessageStatus(statusMessage)(dispatch);
};

export type Actions =
    | AddExceptionAction
    | RemoveExceptionAction
    | AddMessageStatusAction
    | RemoveMessageStatusAction
    | UpdateInsurersListAction
    | UpdateProductSearchQueryAction
    | UpdateLeasersListAction
    | LoadDebitorsAction
    | FormValidationErrorAction
    | SetAutoValidTicketId
    | AutoValidTicketUpdate
    | UpdateCarAction
    | SetCalendarWorker
    | LoadSelectListContainerAction
    | UpdateReviewInsurersListAction;
