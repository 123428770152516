import NAVAPI from "./navApi";
import CarAPI from "./carApi";
import ProductAPI from "./productApi";
import CMSApi from "./cmsApi";
import UserAPI from "./userApi";
import MediaAPI from "./mediaApi";
import AddressApi from "./addressApi";
import SmsApi from "./smsApi";
import EmailApi from "./emailApi";
import DebitorApi from "./debitorApi";
import OrderAPI from "./orderApi";
import OldProductsApi from "./oldProductsApi";

import LogApi from "./logApi";
import ZipCodeDepartmentApi from "./zipCodeDepartmentApi";
import DepartmentApi from "./departmentsApi";
import DeliveryTimeApi from "./deliveryTimeApi";
import TaskJournalApi from "./taskJournalApi";
import CalendarApi from "./calendarApi";
import AvailableSlotsApi from "./availableSlots";
import DepartmentRuleSetApi from "./departmentRuleSet";
import DebitorRuleSetApi from "./debitorRuleSetApi";
import AutoValidVinSearchApi from "./AutoValidVinSearchApi";
import SelectListContainerApi from "./selectListContainerApi";
import FeedbackApi from "./feedbackApi";
import Review from "./reviewApi";
import CapacityOptimizationApi from "./AI/capacityOptimizationApi";
import DepartmentResourceRelationApi from "./department/DepartmentResourceRelationApi";

export default {
    NAVAPI: new NAVAPI(),
    OrderAPI: new OrderAPI(),
    CarAPI: new CarAPI(),
    ProductAPI: new ProductAPI(),
    CMSApi: new CMSApi(),
    UserApi: new UserAPI(),
    MediaApi: new MediaAPI(),
    AddressApi: new AddressApi(),
    SmsApi: new SmsApi(),
    EmailApi: new EmailApi(),
    DebitorApi: new DebitorApi(),
    OldProductsApi: new OldProductsApi(),
    LogApi: new LogApi(),
    ZipCodeDepartmentApi: new ZipCodeDepartmentApi(),
    DepartmentApi: new DepartmentApi(),
    DeliveryTimeApi: new DeliveryTimeApi(),
    TaskJournalApi: new TaskJournalApi(),
    CalendarApi: new CalendarApi(),
    AvailableSlotsApi: new AvailableSlotsApi(),
    DepartmentRuleSetApi: new DepartmentRuleSetApi(),
    DebitorRuleSetApi: new DebitorRuleSetApi(),
    AutoValidVinSearch: new AutoValidVinSearchApi(),
    SelectListContainerApi: new SelectListContainerApi(),
    feedbackApi: new FeedbackApi(),
    CapacityOptimizationApi: new CapacityOptimizationApi(),
    DepartmentResourceRelationApi: new DepartmentResourceRelationApi(),
    Review: new Review()
};
