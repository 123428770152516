import api from "../../api";
import { Action, Dispatch } from "redux";
import { addMessageStatus, updateInsurersList, updateLeasersList } from "../ui/action";
import { MessageBarType } from "@fluentui/react";
import { updateDepartmentsList } from "../department/action";

export interface UserInformationAction extends Action {
    type: "USER_INFORMATION";
    userInformation: UserInformation;
}

export interface UpdateOperatorsListAction extends Action {
    type: "UPDATE_OPERATORS";
    operators: UserInformation[];
}

export interface UpdateUsersListAction extends Action {
    type: "UPDATE_USERS";
    users: UserInformation[];
}

export interface RemoveUserAction extends Action {
    type: "REMOVE_USER";
    user: UserInformation;
}

export const login = (userName: string, password: string) => async (
    dispatch: Dispatch
) => {
    try {
        await api.UserApi.login(userName, password);
        dispatch<any>(getInitialData());
    } catch (e) {
        const statusMessage: StatusMessage = {
            messageType: MessageBarType.error,
            message: `Invalid credentials.`,
            dismissTimer: 5000
        };

        addMessageStatus(statusMessage)(dispatch);

        throw e;
    }
};

export const isUserInRole = (role: UserRole) => async (dispatch: Dispatch) => {
    try {
        await api.UserApi.isUserInRole(role);
    } catch (ex) {
        const statusMessage: StatusMessage = {
            messageType: MessageBarType.error,
            message: `Unable to add user to role`,
            dismissTimer: 5000
        };

        addMessageStatus(statusMessage)(dispatch);

        throw ex;
    }
};

export const registerUser = (user: UserInformation) => async (
    dispatch: Dispatch
) => {
    try {
        await api.UserApi.register(user);
        let users = await api.UserApi.getUsers();
        const action: UpdateUsersListAction = { type: "UPDATE_USERS", users };
        dispatch(action);

        const statusMessage: StatusMessage = {
            messageType: MessageBarType.success,
            message: `User successfully created`,
            dismissTimer: 5000
        };

        addMessageStatus(statusMessage)(dispatch);
    } catch (ex) {
        const statusMessage: StatusMessage = {
            messageType: MessageBarType.error,
            message: `registration failed`,
            dismissTimer: 5000
        };

        addMessageStatus(statusMessage)(dispatch);

        throw ex;
    }
};

export const logout = () => async (dispatch: Dispatch) => {
    await api.UserApi.logout();
    dispatch<any>(clearUserInformation());

    const statusMessage: StatusMessage = {
        messageType: MessageBarType.success,
        message: `User successfully logged out`,
        dismissTimer: 5000
    };

    addMessageStatus(statusMessage)(dispatch);
};

export const resetPassword = (identifier: string | number) => async (
    dispatch: Dispatch
) => {
    await api.UserApi.resetPassword(identifier);

    const statusMessage: StatusMessage = {
        messageType: MessageBarType.success,
        message: `Password successfully reset, check your registered device`,
        dismissTimer: 5000
    };

    addMessageStatus(statusMessage)(dispatch);
};

export const getInitialData = () => async (dispatch: Dispatch) => {
    const userInformation = await api.UserApi.getUserInformation();
    if(userInformation)
    {
        const action: UserInformationAction = {
            type: "USER_INFORMATION",
            userInformation
        };
        dispatch(action);
        updateDepartmentsList()(dispatch);
        updateInsurersList()(dispatch);
        updateLeasersList()(dispatch);
        updateOperators()(dispatch);
    }
   

};

export const clearUserInformation = () => async (dispatch: Dispatch) => {
    const userInformation: UserInformation = {
        name: "",
        initials: "",
        email: "",
        phone: "",
        roles: [],
        departmentId: "",
       
    };
    const action: UserInformationAction = {
        type: "USER_INFORMATION",
        userInformation
    };
    dispatch(action);
};

let fetchingOperators = false;
export const updateOperators = () => async (dispatch: Dispatch) => {
    if (fetchingOperators) {
        return;
    }
    fetchingOperators = true;
    try {
        const operators = await api.UserApi.getUsersInRole("Operation");
        const action: UpdateOperatorsListAction = {
            type: "UPDATE_OPERATORS",
            operators
        };
        dispatch(action);
    } finally {
        fetchingOperators = false;
    }
};

export const getUsersInRole = (role: UserRole) => async (dispatch: Dispatch) => {
    const users = await api.UserApi.getUsersInRole(role);
    const action: UpdateUsersListAction = {
        type: "UPDATE_USERS",
        users
    };
    dispatch(action);
};


export const updateUsers = () => async (dispatch: Dispatch) => {
    const users = await api.UserApi.getUsers();
    const action: UpdateUsersListAction = { type: "UPDATE_USERS", users };
    dispatch(action);
};

export const editUser = (user: UserInformation) => async (
    dispatch: Dispatch
) => {
    try {
        await api.UserApi.editUser(user);
        let users = await api.UserApi.getUsers();
        const action: UpdateUsersListAction = { type: "UPDATE_USERS", users };
        dispatch(action);

        const statusMessage: StatusMessage = {
            messageType: MessageBarType.success,
            message: `User succesfully updated`,
            dismissTimer: 5000
        };

        addMessageStatus(statusMessage)(dispatch);
    } catch (ex) {
        const statusMessage: StatusMessage = {
            messageType: MessageBarType.error,
            message: `update failed`,
            dismissTimer: 5000
        };

        addMessageStatus(statusMessage)(dispatch);

        throw ex;
    }
};

export const removeUser = (user: UserInformation) => async (
    dispatch: Dispatch
) => {
    await api.UserApi.removeUser(user);
    const action: RemoveUserAction = { type: "REMOVE_USER", user };
    dispatch(action);

    const statusMessage: StatusMessage = {
        messageType: MessageBarType.success,
        message: `User succesfully deleted`,
        dismissTimer: 5000
    };

    addMessageStatus(statusMessage)(dispatch);
};

export type Actions =
    | UserInformationAction
    | UpdateOperatorsListAction
    | UpdateUsersListAction
    | RemoveUserAction;
