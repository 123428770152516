import * as React from 'react';
import DropdownPanel from '../../../../components/base/DropdownPanel';
import TipsAndTricks, { hasTipsAndTricks } from '../../../../components/base/TipsAndTricksDialog/TipsAndTricks';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '~/state';
import { getInstructionUrl } from '../../../../state/action';
import { tryGetInstructionUrl } from '../../../../state/product/action';

interface Props
{
    items: PurchaseItem[];
}

const TipsAndTricksContainer: React.FC<Props> = (props: Props) => {
    const kDBUrl = useSelector((state: RootState) => getInstructionUrl(state));
    const dispatch = useDispatch() as any;
    React.useEffect(() =>
    {
        const mainItem = props.items.find(x=>x.isMainItem);
        if(mainItem && mainItem.euroCode)
        {
            dispatch(tryGetInstructionUrl(mainItem.euroCode))
        }
    }, [props.items])
    let hasTipsAndTrick = props.items.some(
        product => hasTipsAndTricks(product) !== undefined
    );
    return (
        <DropdownPanel
            changed={false}
            title={"Tips og tricks"}
            titleBackgroundColor={hasTipsAndTrick || kDBUrl ? "red" : undefined}
            titleColor={hasTipsAndTrick || kDBUrl ? "white" : undefined}
    >
        <>
            <TipsAndTricks
                products={props.items}
                cellWidth={300}
            />
            {kDBUrl && <a href={kDBUrl}>instuktioner</a>}
        </>
        
    </DropdownPanel>
    );
};

export default TipsAndTricksContainer;