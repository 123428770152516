import produce from "immer";
import { Actions } from "./action";
import { ExceptionResults } from "../../components/typings";

export interface UiState {
    statusMessages: StatusMessage[];
    exceptions: Array<ExceptionResults>;
    locations: Location[];
    insurers: Insurer[];
    reviewInsurers?: Insurer[];
    leasers: Leaser[];
    debitors?: Debitor[];
    searchQuery: {
        term?: string;
        searchIn?: string;
    };
    autoValidVinSearchStatus?: "STARTED" | "ENDED" | "NOTREADY";
    calendarOperator?: string;
    selectLists?: SelectListContainer[];
}

const initialState: UiState = {
    statusMessages: [],
    exceptions: [],
    locations: [],
    insurers: [],
    leasers: [],
    searchQuery: {},
    calendarOperator: "",
    
};

export const reducer = (uiState = initialState, action: Actions): UiState =>
    produce(uiState, draft => {
        switch (action.type) {
            case "ADD_MESSAGE_STATUS": {
                const { messageType, message, id } = action;
                var statusMessage = draft.statusMessages.find(x => x.id === id);
                if(statusMessage)
                {
                    statusMessage.messageType = messageType;
                    statusMessage.message = message;
                }
                else
                {
                    draft.statusMessages.push({ messageType, message, id });
                }
                return;
            }
            case "REMOVE_MESSAGE_STATUS": {
                draft.statusMessages = draft.statusMessages.filter(
                    message => message.id !== action.id
                );
                return;
            }
            case "UPDATE_INSURERS_LIST": {
                draft.insurers = action.insurers;
                return;
            }
            case "UPDATE_PRODUCT_SEARCH_QUERY": {
                const { searchIn, term } = action;
                draft.searchQuery = { searchIn, term };
                return;
            }
            case "UPDATE_LEASERS_LIST": {
                draft.leasers = action.leasers;
                return;
            }
            case "LOAD_DEBITORS": {
                draft.debitors = action.debitors;
                return;
            }
            case "ADD_EXCEPTION": {
                draft.exceptions = draft.exceptions.concat(action.exception);
                return;
            }
            case "REMOVE_EXCEPTION": {
                draft.exceptions = draft.exceptions.filter(
                    exception =>
                        exception.stackTrace !== action.exception.stackTrace
                );
                return;
            }
            case "AUTO_VALID_TICKETID": {
                draft.autoValidVinSearchStatus = "STARTED";
                return;
            }
            case "AUTO_VALID_TICKET_UPDATE": {
                draft.autoValidVinSearchStatus = action.status;
                return;
            }
            case "UPDATE_CAR": {
                draft.autoValidVinSearchStatus = undefined;
                return;
            }
            case "SET_CALENDAR_OPERATOR":{
                draft.calendarOperator = action.operator;
                return;
            }
            case "LOAD_SELECT_LIST_CONTAINER":
            {
                draft.selectLists = action.selectList;
                return;
            }
            case "UPDATE_REVIEW_INSURERS_LIST":
            {
                draft.reviewInsurers = action.insurers;
                return;
            }
        }
    });

export const getUILocations = (state: UiState) => state.locations;

export const getStatusMessages = (state: UiState) => state.statusMessages;

export const getExceptions = (state: UiState) => state.exceptions;

export const getInsurers = (state: UiState) => state.insurers;

export const getReviewInsurers = (state: UiState) => state.reviewInsurers;

export const getLeasers = (state: UiState) => state.leasers;

export const getProductSearchQuery = (state: UiState) => state.searchQuery;

export const getDebitors = (state: UiState) => state.debitors;

export const autoValidVinSearchStatus = (state: UiState) =>  state.autoValidVinSearchStatus;

export const getCalendarOperator = (state: UiState) => state.calendarOperator;

export const getSelectList = (state: UiState, area: SelectListArea) => 
{
    if(state.selectLists)
    {
        return state.selectLists.filter(x => x.area === area );
    }
    return undefined;
};

export default reducer;
