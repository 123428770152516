import * as React from "react";
import PageHeader from "../layout/PageHeader";
import Payment from "./worksheetElements/Payment";
import OrderComplete from "./worksheetElements/OrderComplete";
import CarDetails from "./worksheetElements/CarDetails";
import { setInStrict } from "../utils/NestetPropertyHelper";
import DropdownPanel from "../base/DropdownPanel";
import OrderLines from "../products/OrderLines";
import SendTextWindowMobile from "../base/Notifications/SendTextWindowMobile";
import AddProductDialog from "../products/AddProductDialog";
import SendEmailWindow from "../base/Notifications/SendEmailWindow";
import QuickInfo from "./worksheetElements/QuickInfo";
import LogModal from "../log/logModal";
import TipsAndTricks from "./worksheetElements/TipsAndTricks";
import TimeAndDepartmentManager from "../base/TimeAndDepartmentManager";
import ComplaintDialog from "../complaint/ComplaintDialog";
import ComplaintTab from "../complaint/ComplaintTab";
import "./style.css";
import { PrimaryButton, DefaultButton } from "@fluentui/react";
import { useEffect } from "react";
import InfoBar from "./worksheetElements/OrderImages/InfoBar";

interface WorkSheetProps {
    order: Order;
    operators: UserInformation[];
    manufacturers: WindowManufacturer[];
    departments: Department[];
    leasers: Leaser[];
    insurers: Insurer[];
    car: Car;
    debitors?: Debitor[];
    query: { searchIn?: string; term?: string };
    products: ProductGroup[];
    user: UserInformation;
    updateCar: (car: Car) => void;
    updateOrder: (order: Order) => void;
    saveOrder: (order: Order, status?: OrderStatus) => void;
    createMoreSaleOrder: (order: Order) => void;
    setDiscount: (itemNo: string, discount: number) => void;
    removeProduct: (itemNo: string) => void;
    setAmount: (itemNo: string, amount: number) => void;
    setPrice: (itemNo: string, price: number) => void;
    setName: (itemNo: string, name: string) => void;
    updateCustomer: (customer: Partial<Customer>) => void;
    updateDebitor: (debitor: Partial<Debitor>, order: Order) => void;
    whoIsPayingChange: (whoIsPaying: WhoIsPaying) => void;
    updateDebitorFromWhoIsPaying: (whoIsPaying: WhoIsPaying, order: Order, updatePrices: boolean) => void;
    departmentChange: (department: Department, shouldUpdateDebitor: boolean) => void;
    addProducts: (products: Product[], debitorNo: string) => void;
    addProductsAndUpdateOrder: (order: Order, products: Product[], debitorNo: string) => void;
    removeImages: (images: string[], imageType: ImageType) => void;
    sendSms: (model: NotificationModel) => void;
    sendNotificationEmail: (model: NotificationModel) => void;
    sendReceiptEmail: (model: IOrderReceiptModel) => void;
    getDebitor: (query: string, type: ApiType) => void;
    setDebitor: (debitor: Debitor, order: Order) => void;
    updateSearchList: (query: { searchIn: string; term: string }, merge: boolean) => void;
    updateSearchProductQuery: (searchIn?: string, term?: string) => void;
    updateCustomerCityFromZipCode: (zipcode: string) => void;
    addLog: (log: Log) => void;
    updateTimeAndPlace: (timeAndPlace: Partial<TimeAndPlace>) => void;
    updateMobileServiceProduct: (isMobileService: boolean, debitorNo: string, type: AssignmentType) => void;
}

const Worksheet: React.FunctionComponent<WorkSheetProps> = props => {
    const [origDate, setOrigDate] = React.useState<TimeAndPlace>();
    const [itemToRemove, setItemToRemove] = React.useState<PurchaseItem>();
    const [hideLogDialog, setHideLogDialog] = React.useState<boolean>(true);
    const [logType, setLogType] = React.useState<LogType>("CANCELLED");
    const isComplaint = props.order.additionalData.orderType.indexOf("COMPLAINT") > -1;

    useEffect(() => {
        if (props.order.timeAndPlace.date !== "") {
            setOrigTimeAndPlace();
        }
    }, [props.order.timeAndPlace.date]);

    const updateAdditionalDataObject = React.useCallback((additionalData: AdditionalData) => props.updateOrder({ ...props.order, additionalData }), [props.order]);

    const onAdditionalSalesClick = React.useCallback(() => props.createMoreSaleOrder(props.order), [props.order]);
    // Used for FreeCarSku.CAR_ONE
    const onRemoveProductWithItemNo = (itemNo: string) => props.removeProduct(itemNo);

    const onUpdateTimeAndPlace = (timeAndPlace: Partial<TimeAndPlace>) => props.updateTimeAndPlace(timeAndPlace);

    const onLogDialogDismiss = () => setHideLogDialog(true);

    const updateDebitor = (debitor: Partial<Debitor>) => props.updateDebitor(debitor, props.order);

    const setDebitor = (debitor: Debitor) => props.setDebitor(debitor, props.order);

    const countChange = (): number => props.order.additionalData.changes.length;

    const onAddProducts = (items: Product[]) => props.addProductsAndUpdateOrder(props.order, items, props.order.debitor.no);

    const updateAdditionalData = (additionalDataType: string, newValue: string): Order => updateValue(["additionalData", additionalDataType], newValue);

    const updateValue = (nestedFieldName: string[], value: any): Order => {
        const updatedOrderValue: Order = setInStrict({ ...props.order }, nestedFieldName, value);
        const changes = [...updatedOrderValue.additionalData.changes];
        changes.push(nestedFieldName[nestedFieldName.length - 1]);
        const uniqueChanges = Array.from(new Set(changes));
        const additionalData = {
            ...updatedOrderValue.additionalData,
            changes: uniqueChanges
        };
        const fullyUpdatedOrder = { ...updatedOrderValue, additionalData };

        props.updateOrder(fullyUpdatedOrder);

        return fullyUpdatedOrder;
    };

    const saveOrder = (status?: OrderStatus) => {
        let order = props.order;
        if (status) {
            order = updateAdditionalData("orderStatus", status);
        }
        props.saveOrder(order, status);
    };
    const onLogDialogConfirm = (logReason: LogReason, logComment: string) => {
        setHideLogDialog(true);
        const { additionalData, timeAndPlace } = props.order;
        const log: Log = {
            logDate: new Date(),
            logComment: logComment,
            logReason: logReason,
            jobType: additionalData.orderType,
            logType: logType,
            logUser: props.user.name,
            navLocationCode: timeAndPlace.department ? timeAndPlace.department.navLocationCode : "",
            orderNo: additionalData.orderNr || "",
            orgDate: timeAndPlace.date,
            orgTime: timeAndPlace.from && timeAndPlace.to ? timeAndPlace.from + " " + timeAndPlace.to : "",
            regNr: props.order.car.registrationNumber || ""
        };
        // Save log
        props.addLog(log);
        // Save Order
        switch (logType) {
            case "MAINITEMCHANGED":
                if (itemToRemove) {
                    removeProductAndSave(itemToRemove);
                }
                break;
            case "WINDOWREPLACEMOVED":
            case "STONECHIPMOVED":
                setOrigDate(props.order.timeAndPlace);
                saveOrder();
                break;
            default:
                saveOrder(
                    logType !== "MOVED" ? (logType as OrderStatus) : undefined
                );
                break;
        }
    };


    const onSaveOrderClick = async (status?: OrderStatus) => {
        // Check for TimeAndPlace changes
        let date = origDate;
        if (
            date &&
            ((date.department &&
                props.order.timeAndPlace.department &&
                date.department.id !==
                props.order.timeAndPlace.department.id) ||
                date.date !== props.order.timeAndPlace.date ||
                date.from !== props.order.timeAndPlace.from ||
                date.to !== props.order.timeAndPlace.to)
        ) {
            switch (props.order.additionalData.orderType) {
                case "REPLACE_SIDE":
                case "REPLACE_REAR":
                case "REPLACE_FRONT":
                case "REPLACE_ROOF":
                    setLogType("WINDOWREPLACEMOVED");
                    setHideLogDialog(false);
                    break;
                case "REPAIR":
                    setLogType("STONECHIPMOVED");
                    setHideLogDialog(false);
                    break;
                default:
                    setLogType("MOVED");
                    setHideLogDialog(false);
                    break;
            }
        } else {
            // Is this "if" ever triggered?
            if (props.order.additionalData.changes.includes("date")) {
                setLogType("MOVED");
                setHideLogDialog(false);
            } else {
                switch (status) {
                    case "CANCELLED":
                        setLogType("CANCELLED");
                        setHideLogDialog(false);
                        break;
                    case "PROCESSING":
                    case "FINISHED":
                    case "SERVICE_CALL":
                    case "PENDING_REVIEW":
                        saveOrder(status);
                        break;
                    case "ON_HOLD":
                        setLogType("ON_HOLD");
                        saveOrder("ON_HOLD");
                        break;
                    default:
                        saveOrder();
                        break;
                }
            }
        }
    };



    const updateMobileServiceProduct = React.useCallback((isMobileService: boolean) => {
        if (
            props.order.items.findIndex(
                i =>
                    i.itemNo === "REP6" ||
                    i.itemNo === "REP7" ||
                    i.itemNo === "REP8"
            ) < 0
        ) {
            props.updateMobileServiceProduct(
                isMobileService,
                props.order.debitor.no,
                props.order.additionalData.orderType
            );
        }
    }, [props.order]);

    const removeProductAndSave = (item: PurchaseItem) => {
        let orderItems: PurchaseItem[] = props.order.items.filter(filterItem => filterItem.itemNo !== item.itemNo);
        let updatedOrder: Order = {
            ...props.order,
            items: orderItems
        };
        props.saveOrder(updatedOrder);
    };

    const onRemoveProductWithProduct = (item: PurchaseItem) => {
        if (item.isMainItem && ["REPLACE_SIDE", "REPLACE_REAR", "REPLACE_FRONT"].includes(props.order.additionalData.orderType)) {
            // Show log modal
            setItemToRemove(item);
            setLogType("MAINITEMCHANGED");
            setHideLogDialog(false);
        } else {
            removeProductAndSave(item);
        }
    };

    const setOrigTimeAndPlace = () => {
        if (!origDate) {
            setOrigDate(props.order.timeAndPlace);
        }
    };

    const getOrderTypeForCalender = (): AssignmentType => {
        if (
            props.order.additionalData.orderType === "COMPLAINT" && props.order.complaint) {
            if (props.order.complaint.complaintType === "REPAIR_SLOT") {
                return "REPAIR";
            } else if (props.order.complaint.complaintType === "REPLACE_SLOT") {
                return "REPLACE_FRONT";
            }
        }
        return props.order.additionalData.orderType;
    };

    let notificationButtons = null;
    if (
        props.order.timeAndPlace.department &&
        props.order.timeAndPlace.department.mobileService &&
        props.order.additionalData.orderStatus !== "FINISHED" &&
        props.order.additionalData.orderStatus !== "PENDING_REVIEW" &&
        props.order.additionalData.orderStatus !== "CANCELLED"
    ) {
        notificationButtons = (
            <>
                {props.order.customer.allowSms ? (
                    <SendTextWindowMobile
                        order={props.order}
                        sendSms={props.sendSms}
                        margin={true}
                    />
                ) : (
                    <div
                        style={{ marginRight: "10px" }}
                        className="cancel--inline disabled worksheet-cancel cancel_button"
                    >
                        <PrimaryButton disabled text="SMS ikke tilladt" />
                    </div>
                )}
                {props.order.customer.allowEmail ? (
                    <SendEmailWindow
                        order={props.order}
                        sendNotificationEmail={props.sendNotificationEmail}
                        notificationType="ON_MY_WAY"
                        buttonText="SEND 'Jeg er på vej' EMAIL"
                    />
                ) : (
                    <div className="cancel--inline disabled  worksheet-cancel cancel_button">
                        <PrimaryButton disabled text="EMAIL ikke tilladt" />
                    </div>
                )}
            </>
        );
    }
    let complaintTab = null;
    if (isComplaint) {
        complaintTab = (
            <DropdownPanel changed={false} title={"Reklamation"}>
                <ComplaintTab
                    userName={props.user.name}
                    orderStatus={props.order.additionalData.orderStatus}
                    orderType={props.order.additionalData.orderType}
                    complaint={props.order.complaint}
                />
            </DropdownPanel>
        );
    }
    let additionalSaleButton = null;
    if (
        props.order.additionalData.orderStatus !== "FINISHED" &&
        props.order.additionalData.orderStatus !== "CANCELLED" &&
        props.order.additionalData.orderStatus !== "PENDING_REVIEW"
    ) {
        additionalSaleButton = (
            <DefaultButton
                text="Mersalg"
                className="additional-sales"
                onClick={onAdditionalSalesClick}
            />
        );
    }

    return (
        <div className="workSheet__container">
            <InfoBar/>
            <LogModal
                logType={logType}
                onConfirm={onLogDialogConfirm}
                onDismiss={onLogDialogDismiss}
                hideDialog={hideLogDialog}
            />
            <PageHeader
                currentPage="Arbejdskort"
                changes={countChange()}
                noBreadcrumb={true}
            />
            <QuickInfo
                order={props.order}
                updateOrder={props.updateOrder}
                departments={props.departments}
            />
            <br />
            {notificationButtons}
            {additionalSaleButton}
            {
                <ComplaintDialog
                    user={props.user}
                    order={props.order}
                    departments={props.departments}
                />
            }
            <br />
            <br />
            <br />
            {complaintTab}
            <DropdownPanel changed={false} title={"Tid og sted"}>
                <TimeAndDepartmentManager
                    updateTimeOnCashDepartmentChange={false}
                    orderItems={props.order.items}
                    timeAndPlace={props.order.timeAndPlace}
                    additionalData={props.order.additionalData}
                    car={props.order.car}
                    departments={props.departments}
                    debitor={props.order.debitor}
                    orderType={getOrderTypeForCalender()}
                    updateTimeAndPlace={onUpdateTimeAndPlace}
                    updateAdditionalData={updateAdditionalDataObject}
                    updateMobileServiceProduct={updateMobileServiceProduct}
                />
            </DropdownPanel>

            <div className="work__steps">
                <DropdownPanel changed={false} title={"Betalingsinformation"}>
                    <Payment
                        order={props.order}
                        updateCustomer={props.updateCustomer}
                        updateDebitor={updateDebitor}
                        departments={props.departments}
                        insurers={props.insurers}
                        onDepartmentChange={props.departmentChange}
                        whoIsPayingChange={props.whoIsPayingChange}
                        updateDebitorFromWhoIsPaying={
                            props.updateDebitorFromWhoIsPaying
                        }
                        car={props.car}
                        updateCar={props.updateCar}
                        leasers={props.leasers}
                        sendReceiptEmail={props.sendReceiptEmail}
                        debitors={props.debitors}
                        getDebitor={props.getDebitor}
                        setDebitor={setDebitor}
                        updateCustomerCityFromZipCode={
                            props.updateCustomerCityFromZipCode
                        }
                    />
                </DropdownPanel>

                <DropdownPanel changed={false} title={"Bilen"}>
                    <CarDetails owner="Privat" order={props.order} />
                </DropdownPanel>

                <TipsAndTricks items={props.order.items} />

                <DropdownPanel changed={false} title={"Vareliste"}>
                    <div className="product_order__container">
                        <OrderLines
                            useScrollableWrapper={true}
                            setDiscount={props.setDiscount}
                            removeProduct={onRemoveProductWithProduct}
                            setAmount={props.setAmount}
                            setPrice={props.setPrice}
                            setName={props.setName}
                            items={props.order.items}
                            orderStatus={props.order.additionalData.orderStatus}
                        />
                        <AddProductDialog
                            products={props.products}
                            updateSearchProductQuery={
                                props.updateSearchProductQuery
                            }
                            updateSearchList={props.updateSearchList}
                            query={props.query}
                            addProducts={onAddProducts}
                            orderStatus={props.order.additionalData.orderStatus}
                        />
                    </div>
                </DropdownPanel>
                <DropdownPanel
                    open={true}
                    changed={false}
                    title={"Ordreafslutning"}
                >
                    <OrderComplete
                        operators={props.operators}
                        manufacturers={props.manufacturers}
                        onSaveOrderClick={onSaveOrderClick}
                        {...props.order.additionalData}
                        updateAdditionalData={updateAdditionalData}
                        workKm={props.order.additionalData.workKM}
                        order={props.order}
                        products={props.products}
                        sendSms={props.sendSms}
                        sendNotificationEmail={props.sendNotificationEmail}
                        updateSearchProductQuery={
                            props.updateSearchProductQuery
                        }
                        updateSearchList={props.updateSearchList}
                        query={props.query}
                        saveOrder={props.saveOrder}
                        user={props.user}
                        addProducts={props.addProducts}
                        removeProduct={onRemoveProductWithItemNo}
                    />
                </DropdownPanel>
            </div>
        </div>
    );
};

export default Worksheet;