import BaseApi from "./baseApi";

interface IProductAPI {
    getProducts: (carId: number, vin: string) => Promise<ProductGroup[]>;
    searchForMultipleProducts: (search: string) => Promise<ProductGroup[]>;
    getProduct: (search: string) => Promise<Product>;
    getRep: () => Promise<ProductGroup[]>;
    autoComplete: (searchIn: string, searchTerm: string) => Promise<string[]>;
    getInstuctionUrl: (euroCode:string) => Promise<string | undefined>;
}

export default class ProductAPI extends BaseApi implements IProductAPI {
    async getInstuctionUrl(euroCode: string) : Promise<string | undefined>
    {
        const url = `/api/Instruction/${euroCode}`;
        return await this.fetchGet(url);
    }
    async searchForMultipleProducts(search: string): Promise<ProductGroup[]> {
        const url = `/api/product/searchitems?search=${encodeURIComponent(
            search
        )}`;
        return await this.fetchGet(url);
    }

    async search(
        searchIn: string,
        searchTerm: string,
        signal?: AbortSignal
    ): Promise<ProductGroup[]> {
        const url = `/api/product/search/${searchIn}?term=${encodeURIComponent(
            searchTerm
        )}`;
        return await this.fetchGet(url, signal);
    }

    async vinSearch(
        vinNumber: string,
        signal?: AbortSignal
    ): Promise<ProductGroup[]> {
        const url = `/api/product/vinsearch/${vinNumber}`;
        return await this.fetchGet(url, signal);
    }

    async getProduct(search: string): Promise<Product> {
        const url = `/api/product/searchitem?search=${encodeURIComponent(
            search
        )}`;
        return await this.fetchGet(url);
    }

    async autoComplete(
        searchIn: string,
        searchTerm: string
    ): Promise<string[]> {
        const url = `/api/product/autoComplete/${searchIn}?term=${encodeURIComponent(
            searchTerm
        )}`;
        return await this.fetchGet(url);
    }

    async getProducts(
        carId: number,
        vin: string,
        signal?: AbortSignal
    ): Promise<ProductGroup[]> {
        const url = `/api/product/cardata/${carId}?vin=${vin}`;
        return await this.fetchGet(url, signal);
    }

    async getRep(signal?: AbortSignal): Promise<ProductGroup[]> {
        const url = `/api/product/carrep`;
        return await this.fetchGet(url, signal);
    }
}
