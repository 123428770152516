import * as React from "react";
import PageHeader from "../layout/PageHeader";
import NavigationHelper from "../utils/NavigationHelper";
import CustomDatePicker from "../base/CustomDatePicker";
import {
    getOrders,
    getPages,
    getDepartments,
    getUserInformation,
    getUsers,
    getReviewInsurers
} from "../../state/action";
import { RootState } from "../../state";
import {useDispatch, useSelector } from "react-redux";
import { useEffect, FormEvent } from "react";
import { fetchReviewOrders} from "../../state/worksheet/action";
import OrderList from "./OrderList";
import { assignmentTypeText } from "../utils/assignmentTypeHelper";
import SearchButton from "../base/Search/SearchButton";
import ReportModal from "../log/ReportModal";
import { RouteComponentProps } from "react-router";
import { getDepartmentDropdownOptions } from "../utils/departmentHelper";
import Pagination from "../base/Pagination";
import { Dropdown, IDropdownOption, Icon, Label, Link, Stack, TextField } from "@fluentui/react";
import { getUsersInRole } from "../../state/user/action";
import { getReviewInsurersList } from "../../state/ui/action";

function ListAssignments(props:  RouteComponentProps<{ phoneOrMail: string }>) {
    const insurers = useSelector((state: RootState) => getReviewInsurers(state));
    const user = useSelector((state: RootState) => getUserInformation(state));
    const reviewManagers = useSelector((state: RootState) => getUsers(state));
    const departments = useSelector((state: RootState) => getDepartments(state));
    const orders = useSelector((state: RootState) => getOrders(state));
    const ordreCount = useSelector((state: RootState) => getPages(state));
    const [insurance, setInsurance] = React.useState<string>("");
    const [reviewer, setReviewer] = React.useState<string>("");
    const dispatch = useDispatch() as any;
    const [filter, setFilter] = React.useState<ReviewOrderFilter>({
        page: 0,
        orderSortType: "DATE"
    });
    const [showLogModel, setLogModal] = React.useState<boolean>(true);
   
    const toogleLogModal = () => setLogModal(!showLogModel);
    const sortFilter = (): OrderFilter => {
        const fromDate = new Date();
        const toDate = new Date();
        toDate.setMonth(fromDate.getMonth() + 1);
        return {
            ...filter,
            status: "PENDING_REVIEW",
            fromDate,
            toDate,
            department: "all"
        };
       
    };

    useEffect(() => {
        if(!insurers)
        {
            dispatch(getReviewInsurersList());
        }
        if (props.match.params.phoneOrMail) {
            const filer: OrderFilter = {
                orderSortType: "DATE",
                page: 0,
                phoneOrMail: props.match.params.phoneOrMail,
                descendingSort: true
            };
            setFilter(filer);
            dispatch(fetchReviewOrders(filer));
        } else {
            const newFilter = sortFilter();
            setFilter(newFilter);
            dispatch(fetchReviewOrders(newFilter));
        }
        dispatch(getUsersInRole("ReviewManager"))
    }, []);

    const selectOrder = (ordreId: string) => {
        NavigationHelper.pushRoute("WORKSHEET", `/${ordreId}`);
    };

    const changeInsurer = (event: React.FormEvent<HTMLDivElement>, option: IDropdownOption, index: number) =>
    {
        setInsurance(option.key as string);
        setFilter({...filter, insurance: option.key as string})
    } 
    const changeReviewer = (event: React.FormEvent<HTMLDivElement>, option: IDropdownOption, index: number) => setReviewer(option.key as string);
  
    
    const changeLocation = (
        event: React.FormEvent<HTMLDivElement>,
        option: IDropdownOption
    ) => {
        const department =
            option.key !== "all" ? (option.key as string) : undefined;
        setFilter({ ...filter, department });
    };
    const changeType = (
        event: React.FormEvent<HTMLDivElement>,
        option: IDropdownOption
    ) => {
        const assignmentType =
            option.key !== "all" ? (option.key as AssignmentType) : undefined;
        setFilter({ ...filter, assignmentType });
    };

    const onFromDateChange = (fromDate: Date | undefined) =>
        setFilter({ ...filter, fromDate });

    const onToDateChange = (toDate: Date | undefined) =>
        setFilter({ ...filter, toDate });

    const onQueryChange = (
        e: React.SyntheticEvent<HTMLInputElement>,
        query: string
    ) => setFilter({ ...filter, query });

    const setPage = (page: number) => dispatch(fetchReviewOrders({ ...filter, page }));

    const search = (e: FormEvent) => {
        e.preventDefault();
        dispatch(fetchReviewOrders(filter));
    };

    const getDepartmentsOptions = (): IDropdownOption[] => {
        const temp: IDropdownOption[] = getDepartmentDropdownOptions(
            departments
        );
        temp.unshift({ key: "all", text: "Alle" });
        return temp;
    };

    const getTypes = (): IDropdownOption[] => {
        const types = [...assignmentTypeText.filter(x=>x.key.startsWith("REPLACE"))];
        types.unshift({ key: "all", text: "Alle" });
        return types;
    };
    if(insurers === undefined)
    {
        return <></>;
    }

    return (
        <>
            <ReportModal
                onDismiss={toogleLogModal}
                hideDialog={showLogModel}
                title="Download Logs"
                subText="Download Logs"
                apiBase="api/report/ReviewLog"
                params={`&insurance=${insurance}&reviewer=${reviewer}`}
            >
                <Dropdown
                    calloutProps={{calloutMaxHeight: 250, calloutMinWidth: 200}}
                    placeholder="Forsikring"
                    options={insurers.map(x => ({ key: x.debitorId, text: x.name }))}
                    onChange={changeInsurer}
                    selectedKey={insurance}
                />
                <Dropdown
                    calloutProps={{calloutMaxHeight: 250, calloutMinWidth: 200}}
                    placeholder="Gennemsyns bruger"
                    options={reviewManagers.map(x => ({ key: x.id as string, text: `${x.initials} ${x.name}` }))}
                    onChange={changeReviewer}
                    selectedKey={reviewer}
                />
        </ReportModal>
          
            <PageHeader currentPage={"Gennemsyn"} noBreadcrumb={true} />
            {user.roles.indexOf("Adminstrator") >= -1 && <div className="csv_download">
                <Link  onClick={toogleLogModal} target="_blank">
                    Raport <Icon iconName="AnalyticsView" />
                </Link>
            </div>}
            <div className="custom_container">
                <form onSubmit={search}>
                    <Stack
                        className="filter__menu"
                        horizontal
                        tokens={{ childrenGap: 10 }}
                        horizontalAlign="end"
                    >
                        <Stack.Item>
                            <Label>Type</Label>
                            <Dropdown
                                calloutProps={{calloutMaxHeight: 250, calloutMinWidth: 100}}
                                dropdownWidth={200}
                                placeholder="Vælg opgavetype"
                                options={getTypes()}
                                onChange={changeType}
                            />
                        </Stack.Item>
                        <Stack.Item>
                            <Label>Afdeling</Label>
                            <Dropdown
                                calloutProps={{calloutMaxHeight: 250, calloutMinWidth: 100}}
                                dropdownWidth={200}
                                placeholder="Vælg afdeling"
                                options={getDepartmentsOptions()}
                                defaultSelectedKey={filter.department}
                                onChange={changeLocation}
                            />
                        </Stack.Item>
                      <Stack.Item>
                            <Label>Forsikring</Label>
                            <Dropdown
                                calloutProps={{calloutMaxHeight: 250, calloutMinWidth: 200}}
                                placeholder={
                                "Forsikring"
                                }
                                options={insurers.map(x => ({
                                    key: x.debitorId,
                                    text: x.name
                                }))}
                                onChange={changeInsurer}
                                selectedKey={insurance}
                              
                            />
                      </Stack.Item>
                        <Stack.Item>
                            <Label>Fra dato</Label>
                            <CustomDatePicker
                                short={true}
                                date={filter.fromDate}
                                onDateChange={onFromDateChange}
                            />
                        </Stack.Item>
                        <Stack.Item>
                            <Label>Til dato</Label>
                            <CustomDatePicker
                                short={true}
                                date={filter.toDate}
                                onDateChange={onToDateChange}
                            />
                        </Stack.Item>
                        <Stack.Item>
                            <Label>Ordre-/Reg nr. /Email /Debitor no</Label>
                            <TextField
                                value={filter.query}
                                onChange={onQueryChange}
                            />
                            <SearchButton type={"submit"} />
                        </Stack.Item>
                    </Stack>
                </form>
                <br />
                <OrderList
                        orders={orders}
                        selectBooking={selectOrder}
                        user={user}
                    />
               
                {orders ? (
                    <Pagination
                        updatePagination={setPage}
                        currentPage={filter.page}
                        pages={ordreCount}
                    />
                ) : null}
            </div>
        </>
    );
}




export default ListAssignments;
