import { Action, Dispatch, AnyAction } from "redux";
import api from "../../api";
import { addMessageStatus } from "../ui/action";
import { MessageBarType } from "@fluentui/react";

export interface UpdateProductListAction extends Action {
    type: "UPDATE_PRODUCT_LIST";
    productGroups: ProductGroup[];
    merge: boolean;
}

export interface ShowProductGroupAction extends Action {
    type: "SHOW_PRODUCTGROUP";
    checked: boolean;
    groupId: number;
}

export interface ChangeProductAmountAction extends Action {
    type: "CHANGE_PRODUCT_AMOUNT";
    productNo: string;
    amount: number;
}

export interface SetDebitorPrice extends Action {
    type: "SET_DEBITOR_PRICE";
    productNo: string;
    debitorPrice: DebitorPrice;
}

export interface InstructionUrlAction extends Action {
    type: "SET_INSTRUCTION_URL";
    url?: string;
}


export let productSignalController = new AbortController();
export let isProductRequestProcessing = false;

export const updateProductList = (carId: number, vin: string) => async (
    dispatch: Dispatch<AnyAction>
) => {
    try {
        if (isProductRequestProcessing) {
            productSignalController.abort();
        }
        dispatch({
            type: "UPDATE_PRODUCT_LIST",
            productGroups: [],
            merge: false
        } as UpdateProductListAction);
        productSignalController = new AbortController();
        isProductRequestProcessing = true;
        const products = await api.ProductAPI.getProducts(
            carId,
            vin,
            productSignalController.signal
        );
        isProductRequestProcessing = false;
        products.forEach(p => {
            p.checked = true;
            p.items.forEach(i => (i.amount = 1));
        });
        const action: UpdateProductListAction = {
            type: "UPDATE_PRODUCT_LIST",
            productGroups: products,
            merge: false
        };
        dispatch(action);
    } catch (ex) {
        if (typeof ex === "object") {
            const statusMessage: StatusMessage = {
                messageType: MessageBarType.error,
                message: `Api: "getProducts" failed with input ${carId}`,
                dismissTimer: 5000
            };

            addMessageStatus(statusMessage)(dispatch);

            throw ex;
        }
    }
};

export const updateSearchList = (query: { searchIn: string; term: string }, merge: boolean) => async (dispatch: Dispatch<AnyAction>) => {
    try {
        if (!merge) {
            dispatch({
                type: "UPDATE_PRODUCT_LIST",
                productGroups: [],
                merge: false
            } as UpdateProductListAction);
        }
        if (isProductRequestProcessing) {
            productSignalController.abort();
        }
        productSignalController = new AbortController();
        isProductRequestProcessing = true;
        const products = await api.ProductAPI.search(
            query.searchIn,
            query.term,
            productSignalController.signal
        );
        isProductRequestProcessing = false;
        products.forEach(p => {
            p.checked = true;
            p.items.forEach(i => (i.amount = 1));
        });
        const action: UpdateProductListAction = {
            type: "UPDATE_PRODUCT_LIST",
            productGroups: products,
            merge
        };
        dispatch(action);
    } catch (ex) {
        if (typeof ex === "object") {
            const statusMessage: StatusMessage = {
                messageType: MessageBarType.error,
                message: `Api: "updateSearchList" failed with input ${query.term}`,
                dismissTimer: 5000
            };

            addMessageStatus(statusMessage)(dispatch);

            throw ex;
        }
    }
};

export const updateVINSearchList = (vinNumber: string | undefined) => async (
    dispatch: Dispatch<AnyAction>
) => {
    try {
        if (isProductRequestProcessing) {
            productSignalController.abort();
        }
        if (vinNumber === undefined || vinNumber === "") return;

        dispatch({
            type: "UPDATE_PRODUCT_LIST",
            productGroups: [],
            merge: false
        } as UpdateProductListAction);
        productSignalController = new AbortController();
        isProductRequestProcessing = true;
        const products = await api.ProductAPI.vinSearch(
            vinNumber,
            productSignalController.signal
        );
        isProductRequestProcessing = false;
        products.forEach(p => {
            p.checked = true;
            p.items.forEach(i => (i.amount = 1));
        });
        const action: UpdateProductListAction = {
            type: "UPDATE_PRODUCT_LIST",
            productGroups: products,
            merge: false
        };
        dispatch(action);
    } catch (ex) {
        if (typeof ex === "object") {
            const statusMessage: StatusMessage = {
                messageType: MessageBarType.error,
                message: `Api: "updateVINSearchList" failed`,
                dismissTimer: 5000
            };

            addMessageStatus(statusMessage)(dispatch);

            throw ex;
        }
    }
};

export const updateRepList = () => async (dispatch: Dispatch<AnyAction>) => {
    try {
        if (isProductRequestProcessing) {
            productSignalController.abort();
        }
        dispatch({ type: "UPDATE_PRODUCT_LIST", productGroups: [], merge: false } as UpdateProductListAction);
        productSignalController = new AbortController();
        isProductRequestProcessing = true;
        const products = await api.ProductAPI.getRep(productSignalController.signal);
        isProductRequestProcessing = false;
        products.forEach(p => {
            p.checked = true;
            p.items.forEach(i => (i.amount = 1));
        });
        const action: UpdateProductListAction = {
            type: "UPDATE_PRODUCT_LIST",
            productGroups: products,
            merge: false
        };
        dispatch(action);
    } catch (ex) {
        if (typeof ex === "object") {
            const statusMessage: StatusMessage = {
                messageType: MessageBarType.error,
                message: `Api: "updateRepList" failed`,
                dismissTimer: 5000
            };

            addMessageStatus(statusMessage)(dispatch);

            throw ex;
        }
    }
};

export const debitorPriceRequest = ( debitorNo: string, requests: ProductPriceRequest[]) => async (dispatch: Dispatch<AnyAction>) => {
    try {
        const debitorPrices = await api.DebitorApi.getDebitorPrices(
            debitorNo,
            requests
        );
        debitorPrices.forEach(priceResponse => {
            const action: SetDebitorPrice = {
                type: "SET_DEBITOR_PRICE",
                debitorPrice: priceResponse,
                productNo: priceResponse.itemNo
            };
            dispatch(action);
        });
    } catch (ex) {
        throw ex;
    }
};

export const tryGetInstructionUrl = ( euroCode: string ) => async (dispatch: Dispatch<AnyAction>) => {
    try {
        const url = await api.ProductAPI.getInstuctionUrl(euroCode );
        const action: InstructionUrlAction = {
            type: "SET_INSTRUCTION_URL",
            url: url,
        };
        dispatch(action);
      
    } catch (ex) {
        throw ex;
    }
};

export const changeProductAmount = (productNo: string, amount: number): ChangeProductAmountAction => 
    ({ type: "CHANGE_PRODUCT_AMOUNT", productNo, amount });

export const showProductGroup = ( checked: boolean, genericArticleId: number): ShowProductGroupAction => ({ type: "SHOW_PRODUCTGROUP", checked, groupId: genericArticleId });

export type Actions =
    | UpdateProductListAction
    | ShowProductGroupAction
    | ChangeProductAmountAction
    | SetDebitorPrice
    | InstructionUrlAction;
