import produce from "immer";
import { Actions } from "./action";

export interface ProductState {
    productGroups: ProductGroup[];
    instructionUrl?: string;
}

const initialState: ProductState = {
    productGroups: [],
};

export const reducer = (
    productState = initialState,
    action: Actions
): ProductState =>
    produce(productState, draft => {
        if (action.type === "UPDATE_PRODUCT_LIST") {
            if (action.merge) {
                draft.productGroups = draft.productGroups.filter(
                    g =>
                        action.productGroups.findIndex(p => p.id === g.id) ===
                        -1
                );
                draft.productGroups.forEach(group => (group.checked = false));
                draft.productGroups.push(...action.productGroups);
            } else {
                draft.productGroups = action.productGroups;
            }
            draft.productGroups.sort(g => g.id);
            return;
        }

        if (action.type === "SHOW_PRODUCTGROUP") {
            const selectedGroupIndex = draft.productGroups.findIndex(
                x => x.id === action.groupId
            );
            if (selectedGroupIndex > -1) {
                draft.productGroups[selectedGroupIndex].checked =
                    action.checked;
            }
        }

        if (action.type === "CHANGE_PRODUCT_AMOUNT") {
            const { productNo, amount } = action;
            draft.productGroups.forEach(group => {
                group.items.forEach(item => {
                    if (item.no === productNo) {
                        item.amount = amount;
                    }
                });
            });
        }
        if (action.type === "SET_DEBITOR_PRICE") {
            const { productNo, debitorPrice } = action;
            draft.productGroups.forEach(group => {
                group.items.forEach(item => {
                    if (item.no === productNo) {
                        item.debitorPrice = debitorPrice;
                    }
                    if (item.subItems) {
                        item.subItems.forEach(sub => {
                            if (sub.no === productNo) {
                                sub.debitorPrice = debitorPrice;
                            }
                        });
                    }
                });
            });
        }
        if(action.type ==="SET_INSTRUCTION_URL")
        {
            const { url } = action;
            draft.instructionUrl = url;
        }
    });

export const getProducts = (state: ProductState) => state.productGroups;

export const getInstructionUrl = (state: ProductState) => state.instructionUrl;

export default reducer;
